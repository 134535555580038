<template>
  <div class="game_contain wb-100 hb-100">
    <div class="game_head center wb-100 h-100 relative">
      <div class="fz-30 t-w">赛事列表</div>
      <div class="hb-100 fz-24 t-w pointer LogOut center" @click="handleLogOut">
        退出登录
      </div>
    </div>
    <div class="game_content pt-20 pb-34 pl-68 pr-37">
      <div class="tabs_box align-center wb-100">
        <div
          class="tabs_item c-4 mr-200 pointer h-50 center fz-30"
          v-for="(item, index) in tabsList"
          :key="index"
          :class="{ action: item.value == current }"
          @click="handleTabClick(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="contain mt-30" ref="containRef">
        <template v-if="dataList && dataList.length">
          <div class="align-center wb-100 flex-wrap">
            <div
              class="card hidden mr-10 ml-10 mb-31 br-30 pt-20 pl-25 pr-25 pb-27 bg-w pointer"
              v-for="(item, index) in dataList"
              :key="index"
              @click="handleClick(item.id, item.status, item.match_name)"
            >
              <div class="img_box wb-100 h-200">
                <img :src="item.back_pic" alt="" class="wb-100 hb-100" />
              </div>
              <div class="c-6 fz-24 mt-12 mb-12">{{ item.match_name }}</div>
              <div class="align-center">
                <div
                  v-if="item.status == 1"
                  class="zhuangtai w-88 h-36 center br-6 t-w fz-22"
                >
                  进行中
                </div>
                <div
                  v-else-if="item.status == 0"
                  class="zhuangtai w-88 h-36 center br-6 t-w fz-22"
                >
                  未开始
                </div>
                <div v-else class="zhuangtai w-88 h-36 center br-6 t-w fz-22">
                  已结束
                </div>
                <div class="ml-12 c-6 fz-20 nowrap">
                  {{ item.start_time }}-{{ item.end_time }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <el-empty
          description="暂无数据"
          v-else
          class="mt-100 pt-100"
        ></el-empty>
      </div>
      <div class="mt-20 wb-100 row-end" v-if="dataList && dataList.length">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :page-size="page.pageSize"
          :current-page="page.page"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: [],
      current: "",
      tabsList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "进行中",
          value: 1,
        },
        {
          label: "已结束",
          value: 2,
        },
      ],
      page: {
        page: 1,
        pageSize: 10,
        total: -1,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleClick(id, status, title) {
      this.$router.push({
        path: "/live-list",
        query:{
            match_id: id,
            status,
            title
        }
      });
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    handlePageChange(page) {
      this.page.page = page;
      this.$refs.containRef.scrollTop = 0;
      this.getList();
    },
    handleTabClick(value) {
      this.current = value;
      this.page.page = 1;
      this.getList();
    },
    async getList() {
      const {
        page: { page, pageSize, total },
        current,
      } = this;
      const params = {
        per_page: pageSize,
        page,
        status: current,
      };
      const { data } = await this.$get("/match/school/matchList", params);
      this.dataList = data.data;
      this.page.total = data.total;
    },
    handleLogOut() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("pushUrl");
          window.localStorage.removeItem("schoolInfo");
          this.$router.push("/login");
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.h-200 {
  height: 200px;
}
.game_contain {
  background-color: #f5f6fa;
  .game_head {
    background-color: #212526;
  }
  .LogOut {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .mr-200 {
    margin-right: 200px;
  }

  .action {
    color: #226bff;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 6px;
      background-color: #226bff;
      border-radius: 3px;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .contain {
    width: 100%;
    height: calc(100vh - 300px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .card {
    background: #ffffff;
    box-shadow: 0px 0px 23px 1px rgba(34, 107, 255, 0.12);
    width: 573px;
    min-height: 336px;
    .img_box {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      overflow: hidden;
    }
    .zhuangtai {
      background: linear-gradient(90deg, #87dffe 0%, #64a6fe 99%);
    }
  }
}
</style>
